<template>
  <div v-if="metaItem">
    <v-text-field
      dense
      autofocus
      outlined
      maxlength="100"
      counter
      :label="$t('product.data.meta.key')"
      v-model="metaItem.key"
    ></v-text-field>

    <v-textarea
      dense
      outlined
      maxlength="200"
      counter
      :label="$t('product.data.meta.value')"
      v-model="metaItem.value"
    ></v-textarea>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
    data: Object,
  },
  data: () => ({
    metaItem: null,
  }),
  created() {
    this.metaItem = window.eagleLodash.cloneDeep(this.data)
  },
  watch: {
    metaItem: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.metaItem))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>